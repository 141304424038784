@charset "UTF-8";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #161619;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
  color: #fff;
  background-color: #23252C;
  width: 550px;
  height: 868px;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.card {
  background-color: #1A1B21;
  width: 317px;
  border-radius: 0 0 10px 10px;
}

.header h3 {
  font-size: 1.56rem;
  font-weight: 700;
  margin-top: 21px;
}

.header h5 {
  font-size: .81rem;
  font-weight: 400;
  color: #F3BF99;
  margin-top: 6px;
}

.header h6 {
  font-size: .625rem;
  font-weight: 400;
  /* margin-top: 8px;
  margin-bottom: 15px; */
  margin: 8px 0 15px 0;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 30px;
}

.buttons button {
  border: none;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  width: 115px;
  height: 34px;
  color: #374151;
  background-color: #fff;  
  font-size: .875rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.buttons button:hover {
  cursor: pointer;
  opacity: .6;
}

.buttons .lkdn {
  background-color: #5093E2;
  color: #fff;
}

.about,
.interests {
  text-align: left;
  padding: 0 25px; 
}

.about h5,
.interests h5 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 24px;  
}

.about p,
.interests p {
  font-size: .6875rem;
  font-weight: 400;
  line-height: 15px;
  margin-bottom: 25px;
}

.social-icons {
  background-color: #161619;
  color: #918E9B;
  border-radius: 0 0 10px 10px;
  font-size: 1.57rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 64px;
}

.social-icons svg:hover {
  cursor: pointer;
  color: #fff;
}